<template>
	<PageLayout :title="$t('deposit.title')">
		<div v-if="!loading" class="cashier-box cashier-box--deposit">
			<form id="gatewayRedir" action="#" method="post">
				<input type="hidden" name="version" value="1.0" />
				<input id="encodedMessage" type="hidden" name="encodedMessage" />
				<input id="signature" type="hidden" name="signature" />
			</form>
			<div>
				<payment-logo :custom-logo="'cards.svg'" />
				<div class="small-width overflow-visible">
					<payment-wallet />
				</div>

				<div class="line-separator-wrapper">
					<div class="line-separator" />
				</div>

				<amount-buttons
					:buttons-value="currentMethod.predefinedDepositAmounts"
					:form-amount-value="form.amount || currentMethod.predefinedDepositAmounts[0]"
					:currency="'USD'"
					:form-errors="formErrors['amount']"
					@set-button-value="setButtonValue"
				/>

				<div class="small-width">
					<promocode
						:form-promo-value="form.promocode"
						:form-error="formErrors['promocode']"
						@set-promo-value="setPromoValue"
					/>
				</div>

				<button type="button" class="button primary-button deposit center" @click="pay()">
					{{ $t('deposit.top-up') }}
				</button>

				<div v-if="loading" class="loader-wrapper">
					<loader />
				</div>
			</div>
		</div>
	</PageLayout>
</template>
<script>
import PageLayout from '@/components/page-layout/PageLayout';
import apiClient from '../../../../api';
import Analytics from '@/utils/analytics';

const loader = () => import('../../../../shared/loader/Loader.vue');
const paymentLogo = () => import('../../../../shared/payment-logo/PaymentLogo');
const amountButtons = () => import('../../../../shared/amount-buttons/AmountButtons');
const promocode = () => import('../../../../shared/promocode/Promocode');
const paymentWallet = () => import('../../../../shared/payment-wallet/PaymentWallet');

export default {
	name: 'DepositGateway',
	components: {
		PageLayout,
		loader,
		paymentLogo,
		amountButtons,
		promocode,
		paymentWallet,
	},
	data() {
		return {
			cashierUrl: null,
			cashierKey: null,
			cashierToken: null,
			form: {
				amount: null,
				promocode: null,
			},
			formErrors: {
				amount: null,
				promocode: null,
			},
			loading: false,
			paymentDone: false,
			walletId: null,
		};
	},
	computed: {
		currentMethod() {
			return this.$store.getters.getCurrentMethod('skrill');
		},
	},
	async mounted() {
		await this.getUserWallet();
	},
	methods: {
		async getUserWallet() {
			try {
				const { id } = await apiClient.fetchFullWallet(this.$route.params.walletId);
				this.walletId = id;
			} catch (e) {
				this.error = 'wallets.failed-to-load-wallet';
			} finally {
				this.loading = false;
			}
		},
		setButtonValue(event) {
			this.form.amount = event;
		},
		setPromoValue(event) {
			this.form.promocode = event;
		},
		async createTransaction(amount, currency, promocode) {
			const response = await apiClient.gatewayCreateTransaction(this.walletId, amount, currency, promocode);

			if (response.status === 201) {
				return response.data;
			}
			throw new Error('Invalid response from create transaction');
		},
		async pay() {
			this.loading = true;

			try {
				const currency = 'USD';
				const result = await this.createTransaction(this.form.amount, currency, this.form.promocode);

				Analytics.trackEvent({
					event: 'Deposit',
					value: this.form.amount,
					label: 'Gateway',
				});

				const form = document.getElementById('gatewayRedir');
				form.action = result.url;
				document.getElementById('encodedMessage').value = result.encodedMessage;
				document.getElementById('signature').value = result.signature;
				form.submit();
			} catch (e) {
				console.error(e);
				const walletId = this.$route.params.walletId;
				this.$router.push({
					path: `/payments/deposit/${walletId}/confirmation`,
					query: { status: 'error' },
				});
				console.error('Failed to fetch user data', e);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>
<style scoped>
#gatewayRedir {
	visibility: hidden;
}
</style>
